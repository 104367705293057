/* svg * {
  transform-box: fill-box;
}
.a1letter {
  stroke-dashoffset: 141;
  stroke-dasharray: 141;
  stroke: #23a3d0;
  fill: transparent;
  stroke-width: 2px;
  animation: a1_write 2s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

@keyframes a1_write {
  0% {
    stroke-dashoffset: -141;
  }
  50% {
    stroke-dashoffset: 0;
    fill: transparent;
    stroke-width: 2px;
  }
  100% {
    fill: #23a3d0;
    stroke-width: 0px;
  }
}
@keyframes st2_fill {
  0% {
    fill: transparent;
    stroke-width: 2px;
  }
  100% {
    fill: #23a3d0;
    stroke-width: 0px;
  }
}
svg:hover {
  cursor: pointer;
  .st0 {
    transform: translate(-100%, 0%);
  }
  .st1 {
    transform: translate(50%, 0%);
  }
  .a1letter,
  .a2letter,
  .nletter,
  .sletter {
    transform: translate(-60px, 0%);
  }
  .nletter,
  .a2letter,
  .sletter {
    opacity: 1;
    fill: #23a3d0;
  }
  .nletter {
    stroke-dashoffset: 128;
    stroke-dasharray: 128;
    stroke: #23a3d0;
    fill: transparent;
    stroke-width: 2px;
    animation: a1_write 2s;
    animation-fill-mode: forwards;
  }
  .a2letter {
    stroke-dashoffset: 141;
    stroke-dasharray: 141;
    stroke: #23a3d0;
    fill: transparent;
    stroke-width: 2px;
    animation: a1_write 2s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
  }
  .sletter {
    stroke-dashoffset: 82;
    stroke-dasharray: 82;
    stroke: #23a3d0;
    fill: transparent;
    stroke-width: 2px;
    animation: a1_write 2s;
    animation-fill-mode: forwards;
    animation-delay: 2s;
  }
}

.a1letter,
.st1,
.st2 {
  transition: all 1s;
}

.nletter,
.a2letter,
.sletter {
  opacity: 0;
  transition: all 2s;
} */
@media (max-width: 980px) {
  .slider-row {
    max-width: 700px;
  }
}

@media (min-width: 981px) {
  .slider-row {
    max-width: 1160px;
  }
}
.Typist .Cursor {
  display: inline-block;
}
.Typist .Cursor--blinking {
  opacity: 1;
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
